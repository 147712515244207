import { Link } from 'gatsby'
import React from 'react'
import PagePreview from './page-preview'
import * as styles from './page-css/page-preview-grid.module.css'
import { PropTypes } from 'prop-types'

function PagePreviewGrid (props) {

  const { itemColor, title, browseMoreHref, pages}= props


  const styled = {background: itemColor, color: itemColor }


  let pageGrid = styles.grid3Col

  
  return (
    <div className={styles.root}>
      {title && (
        <h2 className={styles.headline}>
          {browseMoreHref ? (
            <Link to={browseMoreHref}>{title}</Link>
          ) : (
            title
          )}
        </h2>
      )}
      <ul className={pageGrid} >
        {pages && (
          pages.map((page, index) => (
            <li key={index} style={styled}> 
              <PagePreview {...page} />
            </li>
          )))}
      </ul>
      {browseMoreHref && (
        <div className={styles.browseMoreNav}>
          <Link to={browseMoreHref}>Browse more</Link>
        </div>
      )}
    </div>
  )
}

PagePreviewGrid.defaultProps = {
  title: 'Error',
  browseMoreHref: '',
  itemColor: 'white',
  textColor: 'black'
}
PagePreviewGrid.propTypes = {
  title: PropTypes.string,
  browseMoreHref: PropTypes.string,
  itemColor: PropTypes.string,
  textColor: PropTypes.string,
  pages: PropTypes.array
}

export default PagePreviewGrid
