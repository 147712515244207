import BaseBlockContent from '@sanity/block-content-to-react'
import React from 'react'
import Figure from './figure'
import { PropTypes } from 'prop-types'
import * as typography from '../typography.module.css'

const serializers = {
  types: {
    block (props) {
      switch (props.node.style) {
      default:
        return <p className={typography.paragraphSansBigger}>{props.children}</p>
      }
    },
    figure(props) {
      return <Figure {...props.node} />
    },
  }
}

const BlockTextSans = ({ blocks }) => <BaseBlockContent blocks={blocks} serializers={serializers} />

BlockTextSans.propTypes = {
  blocks: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
}

export default BlockTextSans
