import { Link } from 'gatsby'
import React from 'react'
import { format, distanceInWords, differenceInDays, parseISO } from 'date-fns'
import { buildImageObj } from '../../lib/helpers'
import { imageUrlFor } from '../../lib/image-url'
import * as styles from './project-preview.module.css'
import { PropTypes } from 'prop-types'

function ProjectPreview (props) {
  const {mainImage, publishedAt, title, slug} = props
  return (
    <Link className={styles.root} to={`/project/${slug.current}`}>
      <div className={styles.item}>

        <div className={styles.leadMediaThumb}>
          {mainImage && mainImage.asset && (
            <img
              src={imageUrlFor(buildImageObj(props.mainImage))
                .width(600)
                .url()}
              alt={mainImage.alt}
            />
          )}
        </div>

        <div className={styles.content}>
          {publishedAt && (
            <div className={styles.publishedAt}>
              {differenceInDays(new Date(publishedAt), new Date()) > 3
                ? distanceInWords(new Date(publishedAt), new Date())
                : format(parseISO(publishedAt), 'MMMM Do yyyy')}
            </div>
          )}
          <h3 className={styles.title}>{title}</h3>
          <p className={styles.readmore}>Read more →</p>
        </div>
      </div>
    </Link>
  )
}

ProjectPreview.propTypes = {
  title: PropTypes.string,
  publishedAt: PropTypes.string,
  mainImage: PropTypes.shape({
    alt: PropTypes.string,
    asset:  PropTypes.shape({
      _id: PropTypes.string,
    })
  }),
  slug: PropTypes.shape({
    current: PropTypes.string,
  })
}

export default ProjectPreview
