import BaseBlockContent from '@sanity/block-content-to-react'
import React from 'react'
import * as typography from '../typography.module.css'
import Figure from './figure'
import Slideshow from './slideshow'
import { VideoIframe } from './VideoIframe'

const serializers = {
  types: {
    block(props) {
      switch (props.node.style) {
      case 'h1':
        return <h1 className={typography.responsiveTitle1}>{props.children}</h1>

      case 'h2':
        return <h2 className={typography.responsiveTitle2}>{props.children}</h2>

      case 'h3':
        return <h3 className={typography.responsiveTitle3}>{props.children}</h3>

      case 'h4':
        return <h4 className={typography.responsiveTitle4}>{props.children}</h4>

        case 'blockquote':
          return (
            <div style={{ position: 'relative', backgroundColor: 'transparent' }}>
          <span className={typography.blockQuoteSymbol}>“</span>
          <blockquote className={typography.blockQuoteLarge}>
            {props.children}
            </blockquote>
            </div>
            )
        case 'quoteColorRed':
          return <blockquote className={typography.blockQuoteRed}>
            “{props.children}”
          </blockquote>
        case 'quoteColorGreen':
          return <blockquote className={typography.blockQuoteGreen}>
            “{props.children}”
          </blockquote>
        case 'quoteRed':
          return(
            <div style={{position:'relative', backgroundColor:'transparent'}}>
              <span className={typography.blockQuoteSymbolRed}>“</span>
              <blockquote className={typography.blockQuoteLarge}>
            {props.children}
          </blockquote>
          </div >
          )

      default:
        return <p className={typography.paragraph}>{props.children}</p>
      }
    },
    figure(props) {
      return <Figure {...props.node} />
    },
    slideshow(props) {
      return <Slideshow {...props.node} />
    },
    youtube: ({ node }) => {
      const { url } = node
      return <VideoIframe url={url}/>
    }
  }
}

const BlockContent = ({ blocks }) => <BaseBlockContent blocks={blocks} serializers={serializers} />

export default BlockContent
