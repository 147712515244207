import React, {useState} from 'react'
import { buildImageObj } from '../../lib/helpers'
import { imageUrlFor } from '../../lib/image-url'
import BlockTextSans from '../block-content/block-text-sans'
import * as styles from './usp-preview.module.css'
import { PropTypes } from 'prop-types'
import BlockContent from './../block-content/index';
import { VideoPlayerModal } from '../VideoPlayerModal'


function UspPreview (props) {
  const [showVideoModal, setShowVideoModal] = useState(false)
  // TODO: Following mapping is quite ugly. Will fix later.
  let uspStyle = "white"
  {props.data.style && (
    props.data.style[0] && (
      uspStyle = props.data.style[0].color
    )
  )}
  let style


  {props.data.style && (
    props.data.style[0] && (  
      style =  props.data.style[0].title
    )
  )}

  const leftGrid = styles.grid
  const rightGrid = styles.gridAlternated
  let bulletStyle = styles.bulletGrid

  if (props.data._rawBullets && props.data._rawBullets.length == 4) {
    bulletStyle = styles.bulletGrid4Bullets
  }
      
  if (style == "Designprinciple") {
    return (
      <div className={styles.mainText}>
        <h3 className={styles.titleAlternative}>{props.data.title}</h3>
        <BlockTextSans blocks={props.data._rawExcerpt} />   
      </div>
    )
  }

  if (style == "Index-feature") {
    return (
      <div className={styles.featuredContainer} style={{backgroundColor:uspStyle}}>
        <div className={styles.gridIndex}>
          <div className={styles.mainImageIndex}>
            {props.data.mainImage && props.data.mainImage.asset && (
              <img
                src={imageUrlFor(buildImageObj(props.data.mainImage))
                  .height(900)
                 
                  .fit('fillmax')
                  .url()}
                alt={props.data.mainImage.alt}
              />
            )}
          </div>

          <div className={styles.indexText}>
            <h5 className={styles.subtitle}>{props.data.subTitle}</h5>
            <h3 className={styles.titleIndex}>{props.data.title}</h3>
            <BlockTextSans blocks={props.data._rawExcerpt} />   
          </div>
        
        </div>

        {props.data.linkUrl && (
          <p className={styles.browseMoreNav}><a href={props.data.linkUrl}>{props.data.linkText} →</a></p>
        )}
      </div>
    )
  }

  if (style == "Product-feature") {
    return (
      <div className={styles.featuredContainer} style={{backgroundColor:uspStyle}}>

        <div className={styles.featuredStyle}>
          <p className={styles.productFeatureIntro} >{props.data.subTitle}</p>
        </div>  

        <div className={styles.mainImage}>
          {props.data.mainImage && props.data.mainImage.asset && (
            <img
              src={imageUrlFor(buildImageObj(props.data.mainImage))
                .height(900)
                .fit('fillmax')
                .url()}
              alt={props.data.mainImage.alt}
            />
          )}
        </div>
        <ul className={bulletStyle}>
          {props.data._rawBullets &&
          props.data._rawBullets.map(bullet => ( 
            <li key={bullet._key}>
              {bullet.image && bullet.image.asset && (
                <div className={styles.bulletImage}>
                  <img
                    src={imageUrlFor(buildImageObj(bullet.image))
                      .height(180)
                      .url()}
                    alt="hei"
                  />
                </div>
              )}
              <div>
                <BlockTextSans blocks={bullet.bulletText} />
              </div>
            </li>
          ))}
        </ul>
        {props.data.linkUrl && (
          <p className={styles.browseMoreNav}><a href={props.data.linkUrl}>{props.data.linkText} →</a></p>
        )}
      </div>
    )
  }

  else if (style == 'Video-feature') {

    return (
      <div className={styles.root} style={{backgroundColor:uspStyle}}>

        <div className={props.grid=='left' ? leftGrid : rightGrid}> 

          <div className={styles.mainTextVideo}> 
            <h5 className={styles.subtitle}>{props.data.subTitle}</h5>
            <h3 className={styles.title}>{props.data.title}</h3>
            <BlockTextSans blocks={props.data._rawExcerpt} />   
          </div> 
          <div className={styles.mainImageVideo} onClick={() => setShowVideoModal(true)}>
          {props.data.mainImage && props.data.mainImage.asset && (
            <img
              src={imageUrlFor(buildImageObj(props.data.mainImage))
                .height(900)
                .fit('fillmax')
                .url()}
              alt={props.data.mainImage.alt}
            />
          )}
          </div>
          {showVideoModal && (
            <VideoPlayerModal
              onClose={() => setShowVideoModal(false)}
              blocks={props.data._rawBody} />
          )}      
        </div>
        <ul className={bulletStyle}>
          {props.data._rawBullets &&
          props.data._rawBullets.map(bullet => (
            <li key={bullet._key}>
              {bullet.image && bullet.image.asset && (
                <div className={styles.bulletImage}>
                  <img
                    src={imageUrlFor(buildImageObj(bullet.image))
                      .height(80)
                      .url()}
                    alt="hei"
                  />
                </div>
              )}
              <div>
                <BlockTextSans blocks={bullet.bulletText} />
              </div>
            </li>
          ))}
        </ul>
        {props.data.linkUrl && (
          <p className={styles.browseMoreNav}><a href={props.data.linkUrl}>{props.data.linkText} →</a></p>
        )}
        <div className={styles.horisontalLine}></div>
      </div>
    )
  }

  else {
    return (
      <div className={styles.root} style={{backgroundColor:uspStyle}}>
        <div className={props.grid=='left' ? leftGrid : rightGrid}>
          <div className={styles.mainText}>
            <h5 className={styles.subtitle}>{props.data.subTitle}</h5>
            <h3 className={styles.title}>{props.data.title}</h3>
            <BlockTextSans blocks={props.data._rawExcerpt} />   
          </div>

          <div className={styles.mainImage}>
            {props.data.mainImage && props.data.mainImage.asset && (
              <img
                src={imageUrlFor(buildImageObj(props.data.mainImage))
                  .height(900)
                  .fit('fillmax')
                  .url()}
                alt={props.data.mainImage.alt}
              />
            )}
          </div>
        </div>

        <ul className={bulletStyle}>
    
          {props.data._rawBullets &&
          props.data._rawBullets.map(bullet => (
            
            <li key={bullet._key}>
              {bullet.image && bullet.image.asset && (
                <div className={styles.bulletImage}>
                  <img
                    src={imageUrlFor(buildImageObj(bullet.image))
                      .height(80)
                      .url()}
                    alt="hei"
                  />
                </div>
              )}
              <div>
                <BlockTextSans blocks={bullet.bulletText} />
              </div>
            </li>
          ))}
        </ul>
        {props.data.linkUrl && (
          <p className={styles.browseMoreNav}><a href={props.data.linkUrl}>{props.data.linkText} →</a></p>
        )}
        <div className={styles.horisontalLine}></div>
      </div>
    )
  }
}

UspPreview.propTypes = {
  data: PropTypes.object,
  grid: PropTypes.string,
  errors: PropTypes.array,
}

export default UspPreview

