import { Link } from 'gatsby'
import React from 'react'
import { cn} from '../../lib/helpers'
import * as styles from './page-css/page-preview.module.css'
import {  paragraphSans } from '../typography.module.css'
import { PropTypes } from 'prop-types'

function PagePreview (props) {
  const {excerpt, slug, title} = props
  return (
    <div className={styles.root}>
      <div className={styles.itemBlock}>
        <h3 className={styles.title}>{title}</h3>
        {excerpt && (
          <div className={cn(paragraphSans, styles.excerpt)}>
            <p>{excerpt}</p>
          </div>
        )}

        {slug && slug.current && (
          <div className={styles.button}>
            <Link to={`/page/${slug.current}`}><button className={styles.buttonText}>{'Learn more'}</button></Link>
          </div>
        )}
      </div>
    </div>
  )
}

PagePreview.propTypes ={
  slug: PropTypes.shape({
    current: PropTypes.string,
  }),
  excerpt: PropTypes.string,
  title: PropTypes.string,
}


export default PagePreview
