import { Link } from 'gatsby'
import React from 'react'
import UsecasePreview from './usecase-preview'
import * as styles from './usecase-preview-grid.module.css'
import { PropTypes } from 'prop-types'

function UseCasePreviewGrid (props) {
  const {title, browseMoreHref, nodes} = props
  return (
    <div className={styles.root}>
      {title && (
        <h2 className={styles.headline}>
          {browseMoreHref ? (
            <Link to={browseMoreHref}>{title}</Link>
          ) : (
            title
          )}
        </h2>
      )}
      <ul className={styles.grid}>
        {nodes &&
          nodes.map(node => (
            <li key={node.id}>
              <UsecasePreview {...node} />
            </li>
          ))}
      </ul>
      {browseMoreHref && (
        <div className={styles.browseMoreNav}>
          <Link to={browseMoreHref}>{ 'Browse more'}</Link>
        </div>
      )}
    </div>
  )
}

UseCasePreviewGrid.defaultProps = {
  title: '',
  nodes: [],
  browseMoreHref: '/usecases'
}
UseCasePreviewGrid.propTypes ={
  title: PropTypes.string,
  nodes: PropTypes.array,
  browseMoreHref: PropTypes.string
}

export default UseCasePreviewGrid
