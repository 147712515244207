import { Link } from 'gatsby'
import React from 'react'
import UspPreview from './usp-preview'
import * as styles from './usp-preview-grid.module.css'
import { PropTypes } from 'prop-types'

function UspPreviewGrid (props) {
  const {nodes, style,title, browseMoreHref}= props
  const alternatingGrids = ['left', 'right'] 
    
  if (style == 'indexFeatured') {
    return (
      <div className={styles.root}>
        <ul >
          {nodes &&
            nodes.map((node, index) => (
              node && (
                <li key={index}>
                  <UspPreview data={node} grid={alternatingGrids[index % alternatingGrids.length]}
                  
                  />
                </li>
              )
            )
            )}
        </ul>

      </div>
    )
  }
    
  if (style == 'productFeatured') {
    return (
      <div className={styles.root}>
        <div className={styles.productFeaturedGrid}>
          <ul >
            {nodes &&
              nodes.map((node, index) => (
                node && (
                  <li key={index}>
                    <UspPreview data={node} grid={alternatingGrids[index % alternatingGrids.length]}
                    />
                  </li>
                )
              )
              )}
          </ul>
        </div>
      </div>
    )
  }

  if (style == 'designprinciples') {
    return (
      <div className={styles.root}>
        <div className={styles.alternativeGrid}>
          <h3 className={styles.headline}>Our design principles</h3>
          <ul >
            {nodes &&
              nodes.map((node, index) => (
                node && (
                  <li key={index}>
                    <UspPreview data={node} grid={alternatingGrids[index % alternatingGrids.length]}
                    />
                  </li>
                )
              )
              )}
          </ul>
        </div>

      </div>
    )
  }

  else {
 
    return (
      <div className={styles.root}>
        {title && (
          <h2 className={styles.headline}>
            {browseMoreHref ? (
              <Link to={browseMoreHref}>{title}</Link>
            ) : (
              title
            )}
          </h2>
        )}
        <ul className={styles.grid}>

          {nodes &&
            nodes.map((node, index) => (
              node && (
                <li key={index}>
                  <UspPreview data={node} grid={alternatingGrids[index % alternatingGrids.length]}
                  />
                </li>
              )
            )
            )}
        </ul>
        {browseMoreHref && (
          <div className={styles.browseMoreNav}>
            <Link to={browseMoreHref}>{'Browse more'}</Link>
          </div>
        )}
      </div>
    )
  }
}

UspPreviewGrid.defaultProps = {
  title: '',
  nodes: [],
  browseMoreHref: '',
}
UspPreviewGrid.propTypes = {
  nodes: PropTypes.array, 
  style: PropTypes.string,
  title: PropTypes.string, 
  browseMoreHref: PropTypes.string,

}

export default UspPreviewGrid
