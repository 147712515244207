import React from 'react'
import getYouTubeId from 'get-youtube-id'
import PropTypes from 'prop-types'
import * as styles from './videoiframe.module.css'

const getVimeoId = url => {
  if (!url?.includes('vimeo')) return null
  if (url?.includes('video')) return url
  const subUrl = url?.split('/')
  return subUrl?.[3] + '?h=' + subUrl?.[4]
}

export const VideoIframe = ({ url }) => {
  const isYoutube = url?.includes('youtube')
  const id = isYoutube ? getYouTubeId(url) : getVimeoId(url)
  if (!id) {
    return null
  }
  const youtubeUrl = `https://www.youtube.com/embed/${id}`
  const vimeoUrl = `https://player.vimeo.com/video/${id}`

  return (
    <div className={styles.videoPlayerContainer}>
      <iframe
        className={styles.iframe}
        title="Video player"
        src={isYoutube ? youtubeUrl : vimeoUrl}
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      />
    </div>
  )
}

VideoIframe.propTypes = {
  url: PropTypes.string.isRequired
}
