
import React from 'react'
import Container from './container'
import ContactDemo from './contact/contact-demo'


export const DemoPitch = () => {
  return (
    <Container fullwidth={true} backgroundColor=' #FAFAFA' textColor='#1A1A1A'>
      <ContactDemo
        title='Learn how we have improved more than 40,000 lives'
        subtitle='Book demo'
      />
    </Container>
  )
}