import { Link } from 'gatsby'
import React from 'react'
import { cn} from '../../lib/helpers'
import * as styles from './usecase-preview.module.css'
import { responsiveTitle3 } from '../typography.module.css'
import { PropTypes } from 'prop-types'

function UsecasePreview (props) {

  const {slug, excerpt, title} = props
  return (
    <Link className={styles.root} to={`/usecase/${slug.current}`}>

      <div className={styles.item} >
        {/*
      <div className={styles.leadMediaThumb}>
        {props.mainImage && props.mainImage.asset && (
          <img
            src={imageUrlFor(buildImageObj(props.mainImage))
              .width(600)
              .height(Math.floor((9 / 16) * 600))
              .url()}
            alt={props.mainImage.alt}
          />
        )}
      </div>
      
      <div>
        <p>Ikon</p>
      </div>
            */}
        <div className={styles.titleItem} >
          <h3 className={cn(responsiveTitle3, styles.title)}>{title}</h3>
        </div>

        <div></div>

      
        {excerpt && (
          <div className={styles.excerpt}>
            <p>{excerpt}</p>
          </div>
        )}
      </div>
      
    </Link>
  )
}
UsecasePreview.propTypes ={
  title: PropTypes.string,
  excerpt: PropTypes.string,
  slug: PropTypes.shape({
    current: PropTypes.string
  })
}

export default UsecasePreview
