import React from 'react'
import { Modal } from './Modal/Modal'
import { VideoIframe } from './block-content/VideoIframe'
import * as styles from './VideoPlayerModal.module.css'
import BlockContent from './block-content'

export const VideoPlayerModal = ({ onClose, url, blocks }) => {

  return (
    <Modal onClose={onClose}>
      <div className={styles.popup}>
        {!blocks &&
          <VideoIframe url={url} />
        }
        {blocks &&
          <BlockContent blocks={blocks} />
        }
      </div>
    </Modal>
  )
}